import axios from "@/axios.js"

export default {
    fetchCollections({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`/collections`)
            .then((response)=>{
                commit('SET_COLLECTIONS',response.data.data)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    
    getCollection({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`/collections/${id}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    updateCollection({commit}, payload){
        return new Promise((resolve,reject)=>{
            payload.bodyFormData.set("_method", 'put' );
            axios.post(`collections/${payload.id}`, payload.bodyFormData)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

    createNewCollection({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('/collections',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    archiveCollection({commit},id){
        return new Promise((resolve,reject)=>{
            axios.delete(`collections/${id}`)
            .then((response)=>{
                commit('DELETE_COLLECTION',id)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
}
