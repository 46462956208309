const ifSuperAdmin = async(to, from, next) => {
  if (localStorage.getItem('role') == 'super-admin') {
    return next();
  }

  next('/home');
};

export default [
    {
        path: '/stores-management/products',
        name: 'stores-management-products',
        
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/stores-management/products/Products.vue')
      },
      {
        path: '/stores-management/products/new/:storeId',
        name: 'stores-management-new-product',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/stores-management/products/NewProduct.vue'),
        props: true
      },
      {
        path: '/stores-management/products/edit/:id',
        name: 'stores-management-edit-product',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/stores-management/products/EditProduct.vue'),
        props:true
      },
      {
        path: '/stores-management/orders',
        name: 'stores-management-orders',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/stores-management/orders/Orders.vue')
      },
      {
        path: '/stores-management/orders/edit/:id',
        name: 'stores-management-edit-order',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/stores-management/orders/EditOrder.vue')
      },
      {
        path: '/stores-management/orders/invoice',
        name: 'stores-management-invoice',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/pages/Invoice.vue'),
        props:true
      },
      {
        path: '/stores-management/stores',
        name: 'stores',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/stores-management/stores/Stores.vue')
      },
      {
        path: '/stores-management/stores/profile/:id',
        name: 'store-profile',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/stores-management/stores/Store-profile.vue')
      },
      {
        path: '/stores-management/stores/new',
        name: 'new-store',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/stores-management/stores/NewStore.vue')
      },
]
