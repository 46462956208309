const ifSuperAdmin = async (to, from, next) => {
    if (localStorage.getItem('role') == 'super-admin') {
        return next();
    }

    next('/home');
};


export default[
    {
        path: '/e-commerce/products',
        name: 'product-list',
        query: {
          page: 1
        },
        component: () => import ('@/views/e-commerce/products/product-list.vue')
    }, {
        path : '/e-commerce/products/new',
        name : 'new-product',
        component : () => import ('@/views/e-commerce/products/new-product.vue')
    }, {
        path : '/e-commerce/collections/add',
        name : 'collections-add',
        component : () => import ('@/views/e-commerce/collections/new-collection.vue'),
        props : true
    }, {
        path : '/e-commerce/collections',
        name : 'collections',
        component : () => import ('@/views/e-commerce/collections/collection-list.vue'),
        props : true
    }, {
        path : '/e-commerce/brands',
        name : 'brands',
        beforeEnter : ifSuperAdmin,
        component : () => import ('@/views/e-commerce/brands/BrandsList.vue'),
        props : true
    }, {
        path : '/e-commerce/collections/edit/:id',
        name : 'collections-edit',
        component : () => import ('@/views/e-commerce/collections/edit-collection.vue'),
        props : true
    }, {
        path : '/e-commerce/products/edit/:id',
        name : 'edit-product',
        component : () => import ('@/views/e-commerce/products/edit-product.vue'),
        props : true
    }, {
        path : '/e-commerce/orders',
        name : 'orders',
        query: {
            page: 1,
            tab:0
        },
        component : () => import ('@/views/e-commerce/orders/orders-list.vue')
    }, {
        path : '/reports/reps',
        name : 'RepReport',
        beforeEnter : ifSuperAdmin,
        component : () => import ('@/views/reports/RepReport.vue'),
        props : true
    }, {
        path : '/reports/transactions',
        name : 'TransactionsReport',
        beforeEnter : ifSuperAdmin,
        component : () => import ('@/views/reports/TransactionsReport.vue'),
        props : true
    }, {
        path : '/reports/users',
        name : 'UserReport',
        beforeEnter : ifSuperAdmin,
        component : () => import ('@/views/reports/UserReport.vue'),
        props : true
    }, {
        path : '/reports/products',
        name : 'ProductReport',
        component : () => import ('@/views/reports/ProductReport.vue'),
        props : true
    }, {
        path : '/e-commerce/orders/new',
        name : 'new-order',
        component : () => import ('@/views/e-commerce/orders/new-order.vue')
    }, {
        path : '/e-commerce/orders/edit/:id',
        name : 'edit-order',
        component : () => import ('@/views/e-commerce/orders/edit-order.vue'),
        props : true
    }, {
        path : '/e-commerce/orders/invoice/:id',
        name : 'e-commerce-invoice',
        component : () => import ('@/views/pages/Invoice.vue'),
        props : true
    }, {
        path : '/e-commerce/offers',
        name : 'offers',
        component : () => import ('@/views/e-commerce/offers/offers-list.vue')
    }, {
        path : '/e-commerce/offers/new',
        name : 'new-offer',
        component : () => import ('@/views/e-commerce/offers/new-offer.vue')
    }, {
        path : '/e-commerce/offers/edit',
        name : 'edit-offer',
        component : () => import ('@/views/e-commerce/offers/edit-offer.vue')
    }, {
        path : '/e-commerce/wishlist/products',
        name : 'wishlist-products',
        component : () => import ('@/views/wishlists/WishlistProducts.vue')
    }, {
        path : '/e-commerce/wishlist/users/:id',
        name : 'wishlist-users',
        component : () => import ('@/views/wishlists/WishlistUsers.vue')
    },
    {
        path : '/e-commerce/categories',
        name : 'categories',
        beforeEnter : ifSuperAdmin,
        component : () => import ('@/views/e-commerce/categories/categories-list.vue'),
        props : true
    }
    ,
    {
        path : '/e-commerce/categories/new',
        name : 'new-category',
        beforeEnter : ifSuperAdmin,
        component : () => import ('@/views/e-commerce/categories/new-category.vue'),
        props : true
    },
    {
        path : '/e-commerce/categories/edit/:id',
        name : 'edit-category',
        beforeEnter : ifSuperAdmin,
        component : () => import ('@/views/e-commerce/categories/edit-category.vue'),
        props : true
    },
    {
        path:'/e-commerce/colors',
        name:'list-vue',
        beforeEnter: ifSuperAdmin,
        component : () => import ('@/views/e-commerce/colors/list-colors.vue'),
        props : true
    },
    {
        path:'/e-commerce/colors/new',
        name:'new-color',
        beforeEnter : ifSuperAdmin,
        component : () => import ('@/views/e-commerce/colors/new-color.vue'),
        props : true
    },
    {
        path:'/e-commerce/price-range/new',
        name:'new-price-range',
        beforeEnter: ifSuperAdmin,
        component: () => import ('@/views/e-commerce/price-range/add-price-range.vue'),
        props: true
    },
    {
        path:'/e-commerce/price-range/view',
        name:'new-price-range',
        beforeEnter: ifSuperAdmin,
        component: () => import ('@/views/e-commerce/price-range/list-price-range.vue'),
        props: true
    },
    {
        path:'/e-commerce/sub-category/view',
        name:'sub-category',
        beforeEnter: ifSuperAdmin,
        component: ()=> import ('@/views/e-commerce/subCategory/list-view.vue'),
        props:true
    },
    {
        path:'/e-commerce/sub-category/add-new',
        name:'add-sub-category',
        beforeEnter: ifSuperAdmin,
        component: ()=> import ('@/views/e-commerce/subCategory/add-new.vue'),
        props:true
    },
    {
        path:'/e-commerce/heighlites/view',
        name:'heighlites',
        beforeEnter: ifSuperAdmin,
        component: ()=> import ('@/views/e-commerce/heighlites/list-heighlites.vue'),
        props:true
    },
    {
        path:'/e-commerce/heighlites/new',
        name:'heighlites-new',
        beforeEnter: ifSuperAdmin,
        component: ()=> import ('@/views/e-commerce/heighlites/add-heighlites.vue'),
        props:true
    }
]
