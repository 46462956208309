/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleAuth from './auth/moduleAuth.js'
import products from './products/moduleProduct.js'
import categories from './categories/moduleCategory.js'
import notifications from './notifications/moduleNotifications.js'
import transactions from './transactions/moduleTransaction.js'
import brandVisitors from './brandVisitors/moduleBrandVisitors.js'
import reports from './reports-section/moduleReports.js'
import HomeStats from './HomeStats/moduleHomeStats.js'
import collections from './collections/moduleCollection.js'
import wishlist from './wishlist/moduleWishlist.js'
import users from './users/moduleUser.js'
import reps from './reps/moduleRep.js'
import banners from './banners/moduleBanner.js'
import orders from './orders/moduleOrder.js'
import coupons from './coupons/moduleCoupon.js'
import stores from './stores/moduleStore.js'
import NotifManager from './notif-manager/moduleNotifManager.js'




Vue.use(Vuex)


export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
      auth: moduleAuth,
      categories,
      products,
      NotifManager,
      transactions,
      brandVisitors,
      reports,
      collections,
      wishlist,
      banners,
      HomeStats,
      users,
      reps,
      orders,
      coupons,
      stores,
      notifications

  },
    strict: process.env.NODE_ENV !== 'production'
})
