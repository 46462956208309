import Vue from 'vue'
import Router from 'vue-router'

//routes
import eCommerceRoutes from "./routes/e-commerce"
import storesManagementRoutes from "./routes/stores-management"
import usersRoutes from "./routes/users"

Vue.use(Router)
const ifSuperAdmin = async(to, from, next) => {
  if (localStorage.getItem('role') == 'super-admin') {
    return next();
  }

  next('/home');
};

const ifAuthenticated = (to, from, next) => {

  if (localStorage.getItem("token")) {
    return next();
  }
  next('/');
};

let children = [
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/notifications',
      name: 'notif',
      component: () => import('@/views/notif/notifications.vue')
    },
    {
      path: '/banners',
      beforeEnter: ifSuperAdmin,
      name: 'banners',
      component: () => import('@/views/banners/Banners.vue')
    },
    {
      path: '/banners/new',
      beforeEnter: ifSuperAdmin,
      name: 'new-banner',
      component: () => import('@/views/banners/NewBanner.vue')
    },
    {
      path: '/banners/edit/:id',
      beforeEnter: ifSuperAdmin,
      name: 'edit-banner',
      component: () => import('@/views/banners/EditBanner.vue')
    },
    {
      path: '/notifications/manager',
      beforeEnter: ifSuperAdmin,
      name: 'notif-manager',
      component: () => import('@/views/notif-manager/notif-manager.vue')
    },
    {
      path: '/archive/orders',
      name: 'archive-orders',
      component: () => import('@/views/archive/Orders.vue')
    },
    {
      path: '/archive/brands',
      name: 'archive-brands',
      beforeEnter: ifSuperAdmin,
      component: () => import('@/views/archive/Brands.vue')
    },
    {
      path: '/archive/products',
      name: 'archive-products',
      component: () => import('@/views/archive/Products.vue')
    },
    {
      path: '/brand-visitors-list',
      name: 'brand-visitors-list',
      component: () => import('@/views/brand-visitors/brand-visitors.vue')
    },
    {
      path: '/brand-visitors-details',
      name: 'brand-visitors-details',
      component: () => import('@/views/brand-visitors/brand-visitors-details.vue')
    },
    {
      path: '/archive/store-orders',
      name: 'archive-store-orders',
      beforeEnter: ifSuperAdmin,
      component: () => import('@/views/archive/StoreOrders.vue')
    },
    {
      path: '/archive/store-products',
      name: 'archive-store-products',
      beforeEnter: ifSuperAdmin,
      component: () => import('@/views/archive/StoreProducts.vue')
    },
    {
      path: '/archive/users',
      name: 'archive-users',
      beforeEnter: ifSuperAdmin,
      component: () => import('@/views/archive/Users.vue')
    },
    {
      path: '/archive/reps',
      name: 'archive-reps',
      component: () => import('@/views/archive/reps-list.vue')
    },
    {
      path: '/archive/sellers',
      name: 'archive-Sellers',
      beforeEnter: ifSuperAdmin,
      component: () => import('@/views/archive/Sellers.vue')
    },

    //Transactions
    {
      path: '/transactions',
      name: 'transactions',
      beforeEnter: ifSuperAdmin,
      component: () => import('@/views/transactions/Transactions.vue')
    },
    //Coupons
    {
      path: '/coupons',
      name: 'coupons',
      component: () => import('@/views/coupons/coupons-list.vue')
    },
    {
      path: '/new-coupon',
      name: 'new-coupons',
      component: () => import('@/views/coupons/new-coupon.vue')
    },
    // 404 err
    {
      path: '/pages/error-404',
      name: 'page-error-404',
      component: () => import('@/views/pages/Error404.vue')
    },
    ...eCommerceRoutes,
    ...storesManagementRoutes,
    ...usersRoutes,
  ]

const router = new Router({
    base: process.env.BASE_URL,
    scrollBehavior() {
      return {
        x: 0,
        y: 0
      };
    },
    routes: [
        {
            path: '/',
            name: 'login',
            component: () => import('@/views/login/Login.vue'),
        },
        {
            path: '/main',
            component: () => import('@/layouts/main/Main.vue'),
            beforeEnter: ifAuthenticated,
            children: children
        },
        {
            path: '*',
            redirect: '/pages/error-404'
        },
    ],

  })
  router.afterEach(() => {
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
      appLoading.style.display = "none";
    }
  });
  export default router;
