import axios from "@/axios.js"

export default {
    //ecommerce
    fetchTransaction({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`/Transactions/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchTransactions(){
        return new Promise((resolve,reject)=>{
            axios.get(`/transactions`)
            .then((response)=>{
                resolve(response.data.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchInvoices(){
        return new Promise((resolve,reject)=>{
            axios.get(`/transactions/invoices`)
            .then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchPaymentMethods(){
        return new Promise((resolve,reject)=>{
            axios.get(`/transactions/invoices`)
            .then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    createTransaction({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('/transactions',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    updateTransaction({commit},payload){
        return new Promise((resolve,reject)=>{
          payload.set("_method", 'put' );
          axios.post(`/transactions/${payload.get('id')}`,payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchTransactionOptions({commit}){
        return new Promise((resolve,reject)=>{
            axios.get("Transactions/data")
            .then((response)=>{
                commit('SET_OPTIONS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchSubCategories({commit},categoryId){
        return new Promise((resolve,reject)=>{
            axios.get(`/Transactions/sub-categories/${categoryId}`)
            .then((response)=>{
                commit('SET_SUB_CATEGORIES',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchBrands({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('Transactions/brands')
            .then((response)=>{
                commit('SET_BRANDS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchColors({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('Transactions/colors')
            .then((response)=>{
                commit('SET_COLORS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchOffers({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('/offers')
            .then((response)=>{
                commit('SET_OFFERS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchGenders({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('Transactions/genders')
            .then((response)=>{
                commit('SET_GENDERS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    archiveTransaction({commit},id){
        return new Promise((resolve,reject)=>{
            axios.delete(`/Transactions/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    retrieveTransaction({commit},id){
        return new Promise((resolve,reject)=>{
            axios.post(`/Transactions/restore/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchArchivedTransactions({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('Transactions/archive')
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },


    //////
    // class-b
    fetchClassBTransactions({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('/Transactions/class-b/index',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    createClassBTransaction({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('/Transactions/class-b/store',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
}
