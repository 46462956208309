import axios from '@/axios.js'

export default {
  fetchReps({commit}){
    return new Promise((resolve, reject)=>{
      axios.get('/deliveries')
      .then((response)=>{
        commit('SET_REPS',response.data.data)
        resolve(response.data.data)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  fetchArchivedReps({commit}){
    return new Promise((resolve, reject)=>{
      axios.get('/deliveries/archive')
      .then((response)=>{
         commit('SET_ARCHIVED_REPS',response.data.data)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  createNewRep({commit},payload){
      return new Promise((resolve,reject)=>{
          axios.post(`/deliveries`, payload)
          .then((response)=>{
              resolve(response.data.data)
          }).catch((error)=>{
              reject(error)
          })
      })
  },
  updateRep({commit},payload){
      return new Promise((resolve,reject)=>{
          payload.bodyFormData.set("_method", 'put' );
          axios.post(`/deliveries/${payload.id}`,payload.bodyFormData)
          .then((response)=>{
              resolve(response)
          }).catch((error)=>{
              reject(error)
          })
      })
  },
  archiveRep({commit},itemId){
        return new Promise((resolve,reject)=>{
            axios.delete(`/deliveries/${itemId}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
  retrieveRep({commit},itemId){
      return new Promise((resolve,reject)=>{
          axios.post(`/deliveries/restore/${itemId}`)
          .then((response)=>{
              resolve(response)
          }).catch((error)=>{
              reject(error)
          })
      })
  },
  // added "rep profile" just in case it was needed in the future
  repProfile({commit},itemId){
      return new Promise((resolve,reject)=>{
          axios.post(`/deliveries/${itemId}`)
          .then((response)=>{
              resolve(response)
          }).catch((error)=>{
              reject(error)
          })
      })
  },
}
