import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework

import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)
import firebase from 'firebase'
var firebaseConfig = {
  apiKey: "AIzaSyBJ8mkIHjjHKCAMw799BFoMIqe3dhKqs3I",
  authDomain: "digix-azyaa.firebaseapp.com",
  projectId: "digix-azyaa",
  storageBucket: "digix-azyaa.appspot.com",
  messagingSenderId: "810478801047",
  appId: "1:810478801047:web:34db66fa4d0a3c08da99d2",
  measurementId: "G-0S8RBL8SHX"
};
firebase.initializeApp(firebaseConfig);

// Axios Mock Adapter
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

Vue.use(VueFileAgent);
//location picker
import * as LocationPicker from 'vue2-location-picker'
Vue.use(LocationPicker, {
    installComponents: false, // If true, create it globally
})


// axios
import axios from "./axios.js"
Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'

// Vuexy Admin Filters
import './filters/filters'

// Styles: SCSS
import './assets/scss/main.scss'
import './assets/scss/custom.scss'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from '@/router'


// Vuex Store
import store from './store/store'

import Common from './common-plugin';
Vue.use(Common);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

import VeeValidate, { Validator } from 'vee-validate';
import attributesAR from 'vee-validate/dist/locale/ar'
import attributesEn from 'vee-validate/dist/locale/en'
Validator.localize('ar',attributesAR);
Vue.use(VeeValidate, {
  locale: 'ar',
  errorBagName: 'errors',
  fieldsBagName: 'inputs',
  dictionary: {
    translationsEn: { attributes: attributesEn },
    translationsAR: { attributes: attributesAR }
  }});
// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
