import state from './moduleProductState.js'
import mutations from './moduleProductMutations.js'
import actions from './moduleProductActions.js'
import getters from './moduleProductGetters.js'

const namespaced = true

export default {
  namespaced,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}