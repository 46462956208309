const Common = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.
  install(Vue) {
    // We call Vue.mixin() here to inject functionality into all components.
    Vue.mixin({
      data() {
        return {
          default_img: '/img/admin.ce14d64d.png'
        }
      },
      computed: {
        transactionStatuses() {
          return [{
              value: 'under_review',
              label: "قيد المراجعة"
            },
            {
              value: 'confirmed',
              label: "مؤكدة"
            },
            {
              value: 'cancelled',
              label: "ملغية"
            },
          ]
        },
        role() {
          return localStorage.getItem("role");
        },
        orderStatuses() {
          return [{
              value: 1,
              label: "قيد المراجعة",
            },
            {
              value: 2,
              label: "معلق",
            },
            {
              value: 3,
              label: "قيد التجهيز",
            },
            {
              value: 4,
              label: "قيد التوصيل",
            },
            {
              value: 5,
              label: "تم التوصيل",
            },
            {
              value: 6,
              label: "تم الالغاء",
            },
            {
              value: 7,
              label: "تم الرفض",
            },
          ];
        },
        paymentMethods() {
          return [{
              value: 1,
              label: "بطاقة مصرفية",
            },
            {
              value: 2,
              label: "كاش",
            },
            {
              value: 3,
              label: "سداد",
            },
          ];
        },
      },
      methods: {
        formatDate(date) {
          let formatted_date = "";
          if (date) {
            var new_date = new Date(date);
            formatted_date =
              new_date.getFullYear() +
              "-" +
              (new_date.getMonth() > 8 ? new_date.getMonth() + 1 : "0" + (new_date.getMonth() + 1)) + "-" +
              (new_date.getDate() > 9 ? new_date.getDate() : "0" + new_date.getDate());
          }
          return formatted_date
        },
        GetClassAAccess: function () {
          let info = JSON.parse(localStorage.getItem("userInfo"))
          return info["class_a_access"]
        },
        GetFeaturedAccess: function () {
          let info = JSON.parse(localStorage.getItem("userInfo"))
          return info["is_featured"]
        },
        formatDateHour(date) {
          let formatted_date = "";
          if (date) {
            var new_date = new Date(date);
            formatted_date =
              new_date.getHours() + ":" + new_date.getMinutes() + " | " +
              new_date.getFullYear() + "-" + (new_date.getMonth() > 8 ? new_date.getMonth() + 1 : "0" + (new_date.getMonth() + 1)) + "-" +
              (new_date.getDate() > 9 ? new_date.getDate() : "0" + new_date.getDate());

          }
          return formatted_date
        },


        successDialog(response) {

          this.$vs.notify({
            title: "تم !",
            text: response,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });
        },
        errorDialog(error) {
          let errorMessage = error.response.data.errors ? error.response.data.errors : error.message;
          this.$vs.notify({
            time: 10000,
            title: "خطأ !",
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        },

        numberOnly(val) {

          if (typeof (val[1]) === 'number') {
            return val
          }
        },
        getSizeTypes() {
          return [{
              key: "حروف",
              value: "letters"
            },
            {
              key: "ارقام",
              value: "numbers"
            },
            {
              key: "رضّع",
              value: "babies"
            },
            {
              key: "اطفال",
              value: "kids"
            },
          ]
        },
        getSizes() {
          return {
            letters: ['ستنادرد', 'XXS','XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'],
            numbers: Array.from(Array(301).keys()),
            babies: ['0-3', '3-6', '6-12', '12-18', '18-24', '24-36'],
            kids: [
              '3-4 سنوات',
              '4-5 سنوات',
              '5-6 سنوات',
              '6-7 سنوات',
              '7-8 سنوات',
              '8-9 سنوات',
              '9-10 سنوات',
              '10-11 سنوات',
              '11-12 سنوات',
              '12-13 سنوات',
              '13-14 سنوات',
              '14-15 سنوات',
              '15-16 سنوات',
            ],
          }
        },
        imageLoadError: function (event) {
          event.target.src = "https://via.placeholder.com/100/b3b2b2/000000?text=HIT";
        },
        onHistoryBack() {
          if (window.history && window.history.length && window.history.length > 1)
            window.history.back();
          else
            window.close();
        },
        GlobalArrayDifference(a1, a2) {
          var a = [],
            diff = [];
          for (var k = 0; k < a1.length; k++) {
            a[a1[k]] = true;
          }
          for (var i = 0; i < a2.length; i++) {
            if (a[a2[i]]) {
              delete a[a2[i]];
            } else {
              a[a2[i]] = true;
            }
          }
          for (var j in a) {
            diff.push(j);
          }
          return Boolean(diff.length);
        },
        checkIfObjectHasKeys(obj) {
          //to check whether object is not blank and has keys
          try {
            if (obj)
              return Object.keys(obj).length ? true : false
            else
              return false
          } catch (e) {
            return false
          }
        },
        // is_mobile_device() {
        //   // device detection
        //   if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        //     return true
        //   }
        //   else {
        //     return false
        //   }
        // },
        
        get_browser_info() {
          var ua = navigator.userAgent,
            tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
          if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return {
              name: 'IE ',
              version: (tem[1] || '')
            };
          }
          if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR\/(\d+)/)
            if (tem != null) {
              return {
                name: 'Opera',
                version: tem[1]
              };
            }
          }
          M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
          if ((tem = ua.match(/version\/(\d+)/i)) != null) {
            M.splice(1, 1, tem[1]);
          }
          return {
            name: M[0],
            version: M[1]
          };
        }
      }
    })
  }

}

export default Common;
