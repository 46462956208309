import state from './moduleTransactionState.js'
import mutations from './moduleTransactionMutations.js'
import actions from './moduleTransactionActions.js'
import getters from './moduleTransactionGetters.js'

const namespaced = true

export default {
  namespaced,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
