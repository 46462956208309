import axios from "@/axios.js"

export default {
  fetchYearVisitors({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/brand/visitors`, payload)
        .then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
}
