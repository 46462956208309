import state from './moduleRepState.js'
import mutations from './moduleRepMutations.js'
import actions from './moduleRepActions.js'
import getters from './moduleRepGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}