import axios from "@/axios.js"

export default {
    fetchStores({commit}){
        return new Promise((resolve, reject)=>{
          axios.get('/stores')
          .then((response)=>{
            commit('SET_STORES', response.data.data)
            resolve(response)
          })
          .catch((error)=>{
            reject(error)
          })
        })
    },
    fetchStore({commit},id){
      return new Promise((resolve, reject)=>{
        axios.get(`/stores/${id}`)
        .then((response)=>{
          resolve(response)
        })
        .catch((error)=>{
          reject(error)
        })
      })
    },
    createStore({commit},payload){
      return new Promise((resolve,reject)=>{
        axios.post(`/stores`,payload)
        .then((response)=>{
          resolve(response)
        })
        .catch((error)=>{
          reject(error)
        })
      })
    },
    updateStore({commit},payload){
      return new Promise((resolve,reject)=>{
        axios.post(`/stores/${payload.get('id')}`,payload)
        .then((response)=>{
          resolve(response)
        })
        .catch((error)=>{
          reject(error)
        })
      })
    },
    createStoreAdmin({commit},payload){
      return new Promise((resolve,reject)=>{
        axios.post(`/users`,payload)
        .then((response)=>{
          resolve(response)
        })
        .catch((error)=>{
          reject(error)
        })
      })
    },
    fetchStoreAdmins({commit}){
      return new Promise((resolve, reject)=>{
        axios.get('/users/store-admin')
        .then((response)=>{
          commit('SET_ADMINS',response.data.data);
          resolve(response)
        })
        .catch((error)=>{
          reject(error)
        })
      })
    },
    fetchCities({commit}){
      return new Promise((resolve, reject)=>{
        axios.get('/cities')
        .then((response)=>{
          commit('SET_CITIES',response.data.data);
          resolve(response)
        })
        .catch((error)=>{
          reject(error)
        })
      })
    },
}