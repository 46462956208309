import state from './moduleNotificationState'
import mutations from './moduleNotificationMutations'
import actions from './moduleNotificationActions.js'
import getters from './moduleNotificationGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}