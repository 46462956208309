const ifSuperAdmin = async(to, from, next) => {
  if (localStorage.getItem('role') == 'super-admin') {
    return next();
  }
  next('/home');
};

export default [
    {
        path: '/users',
        name: 'users',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/users-management/customers/Users.vue')
      },
      {
        path: '/reps',
        name: 'reps',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/reps/reps-list.vue')
      },
      {
        path: '/sellers',
        name: 'sellers',
        beforeEnter: ifSuperAdmin,
        component: () => import('@/views/users-management/sellers/Sellers.vue')
      },
]