import state from './moduleCouponsState'
import mutations from './moduleCouponsMutations'
import actions from './moduleCouponsActions'
import getters from './moduleCouponsGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}