import axios from "@/axios.js"

export default {
  fetchBanners({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/banners`)
        .then((response) => {
          commit('SET_BANNERS', response.data.data);
          resolve(response.data.data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  productSKUs({commit}){
    return new Promise((resolve,reject)=>{
        axios.get(`/products/skus`)
        .then((response)=>{
            resolve(response.data.data)
        }).catch((error)=>{
            reject(error)
        })
    })
},
  deleteBanner({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/banners/${itemId}`)
        .then((response) => {
          commit('DELETE_BANNER', itemId);
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createBanner({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/banners`, payload)
        .then((response) => {
          commit('ADD_NEW_BANNER', response.data.data);
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateBanner({ commit }, payload) {
    return new Promise((resolve, reject) => {
      payload.bodyFormData.set("_method", 'put');
      axios.post(`/brands/${payload.brand_id}`, payload.bodyFormData)
        .then((response) => {
          commit('UPDATE_BRAND', response.data.data);
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
}
