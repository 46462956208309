import axios from '@/axios.js'
export default {
  fetchWishlistUsers({commit},itemId){
    return new Promise((resolve, reject)=>{
      axios.get(`/wishlist/${itemId}`)
      .then((response)=>{
        commit('SET_WISHLIST_USERS',response.data.data)
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  fetchWishlistProducts({commit}){
    return new Promise((resolve, reject)=>{
      axios.get('/wishlist')
      .then((response)=>{
        commit('SET_WISHLIST_PRODUCTS',response.data.data)
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  giveWishlistDiscount({commit},payload){
    return new Promise((resolve, reject)=>{
      axios.post(`/wishlist/discount/${payload.id}`,payload.bodyFormData)
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  changeStatus({commit},payload){
    return new Promise((resolve,reject)=>{
      axios.post(`api/dashboard/users/change-status/${payload.get('id')}`,payload)
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  createAdmin({commit},payload){
    return new Promise((resolve,reject)=>{
      axios.post(`api/dashboard/users/store`,payload)
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }
}