import axios from "@/axios.js"

export default {
    //e-commerce

    //changes the currentPage state
    changePage({commit},page){
        commit('SET_PAGE', page);
    },
    fetchCategories({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`/categories`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchQuantities({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`products/quantities/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchProducts({commit},param){
        return new Promise((resolve,reject)=>{
            axios.get(`/products?${param}`)
            .then((response)=>{
                commit('SET_PRODUCTS',response.data.data.data)
                commit('SET_PAGINATION',response.data.data.last_page)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    createProduct({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('/products',payload)
            .then((response)=>{
                resolve(response)
              
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    updateProduct({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`/products/${payload.get('id')}`,payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchProductOptions({commit}){
        return new Promise((resolve,reject)=>{
            axios.get("products/data")
            .then((response)=>{
                commit('SET_OPTIONS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    getCategories({commit}){
        return new Promise((resolve,reject)=>{
            axios.get("products/all-main-categories")
            .then((response)=>{
                commit('SET_CATEGORIES',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    getSubCategories({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`products/sub-categories/${id}`)
            .then((response)=>{
                commit('SET_SUB_CATEGORIES',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchFilteredProducts({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post("products/filter",payload)
            .then((response)=>{
                commit('SET_PRODUCTS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchBrands({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('products/brands')
            .then((response)=>{
                commit('SET_BRANDS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchColors({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('products/colors')
            .then((response)=>{
                commit('SET_COLORS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchOffers({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('/offers')
            .then((response)=>{
                commit('SET_OFFERS',response.data.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    archiveProduct({commit},id){
        return new Promise((resolve,reject)=>{
            axios.delete(`/products/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    retrieveProduct({commit},id){
        return new Promise((resolve,reject)=>{
            axios.post(`/products/restore/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchArchivedProducts({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('products/archive')
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    productsSKU({commit},params){
        return new Promise((resolve,reject)=>{
            axios.get(`/products/skus`,params)
            .then((response)=>{
                commit('SET_SKU',response.data.data)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    
    
    //////
    // class-b
    fetchClassBProducts({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('/products/class-b/index',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    createClassBProduct({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('/products/class-b/store',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
}
