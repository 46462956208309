export default {
    SET_ARCHIVED(state,archived){  state.archived = archived},
    SET_PAGINATION(state,pagination){ state.pagination = pagination},
    LoadingRequest(state, val) {
        state.Loading = val;
    },
    SET_ORDERS(state,orders){ state.orders = orders},
    DELETE_ORDER(state,order){
        state.orders.forEach((item,i )=> {
            if(item.id == order) {
                state.orders.splice(i, 1)  }
        })
    },
}