import state from './moduleWishlistState.js'
import mutations from './moduleWishlistMutations.js'
import actions from './moduleWishlistActions.js'
import getters from './moduleWishlistGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}