import axios from "@/axios.js"

export default {
  //ecommerce
  fetchStats({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/statistics`)
        .then((response) => {
          commit('SET_STATS', response.data.data);
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  

  fetchOrderStats({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/orders/statistics`)
        .then((response) => {
          commit('SET_ORDER_STATS', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateRate({  },payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/update_local_rate`,payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCurrencies({commit}){
    return new Promise((resolve,reject)=>{
        axios.get(`/currencies`)
        .then((response)=>{
            localStorage.setItem('rate',response.data.data[68].reverse_rate)
            commit('SET_CURRENCIES',response.data.data);
            
            resolve(response)
        }).catch((error)=>{
            reject(error)
        })
    })
},
  fetchTransactions({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/transactions/statistics`)
        .then((response) => {
          commit('SET_TRXN_STATS', response.data.data);
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
