import state from './moduleReportsState.js'
import mutations from './moduleReportsMutations.js'
import actions from './moduleReportsActions.js'
import getters from './moduleReportsGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
