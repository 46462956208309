/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"


// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

const userDefaults = {
  name    : localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).name : "مشرف", // From Auth
  image   : localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).image : require("@/assets/images/portrait/small/admin.png"), // From Auth
  status  : "online", // From Aut
  phone   : localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).phone : "0928776765",
  address : localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).address : "حي الاندلس",
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser           : userDefaults,
    DashboardStatistics     : {},
    products                : [],
    filters                 : [],
    packages                : [],
    collections             : [],
    users                   : [],
    coupons                 : [],
    bodyOverlay             : false,
    isVerticalNavMenuActive : true,
    mainLayoutType          : themeConfig.mainLayoutType || "vertical",
    navbarSearchAndPinList  : navbarSearchAndPinList,
    reduceButton            : themeConfig.sidebarCollapsed,
    verticalNavMenuWidth    : "default",
    verticalNavMenuItemsMin : false,
    scrollY                 : 0,
    starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
    theme                   : themeConfig.theme || "light",
    themePrimaryColor       : colors.primary,
    
    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
}

export default state
