export default {
    isUserLoggedIn: () => {
        let isAuthenticated = false

        // get firebase current user
        if(localStorage.getItem('token')){
            isAuthenticated = true
        }

        return (isAuthenticated)
    },
}