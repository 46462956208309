export default {
    SET_COUPONS(state,coupons){  state.coupons = coupons; },
    DELETE_COUPON(state,coupon){
        state.coupons.forEach((item,i )=> {
            if(item.id == coupon) {
                state.coupons.splice(i, 1)  }
        })
    },
    

}