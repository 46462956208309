export default {
    SET_ARCHIVED_USERS(state,users){  state.archived = users; },
    SET_USERS(state,users){  state.users = users; },
    SET_UNPAGINATED_USERS(state,unpaginated){  state.unpaginated = unpaginated; },
    SET_PAGINATION(state,pagination){ state.pagination = pagination; },
    SET_ARCHIVED_SELLERS(state,sellers){  state.archived_sellers = sellers; },
    DELETE_USER(state,user){  
    state.users.forEach((item,i )=> {
        if(item.id == user) {
            state.users.splice(i, 1)  }
    })
},
DELETE_ARCHIVED_USER(state,user){
    state.archived.forEach((item,i )=> {
        if(item.id == user) {
            state.archived.splice(i, 1)  }
    })
},

    SET_STORES(state,stores){  state.stores = stores; },
}
