import state from './moduleCollectionState.js'
import mutations from './moduleCollectionMutations.js'
import actions from './moduleCollectionActions.js'
import getters from './moduleCollectionGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
