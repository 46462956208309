import axios from "@/axios.js"


export default {
    login({commit},payload){
        return new Promise((resolve,reject) => {
           axios.post('/admin/login',payload)
           .then(function (response){
            
            localStorage.setItem('token', response.data.data.token,)
            localStorage.setItem('role', response.data.data.user.role)

            commit('UPDATE_USER_INFO', response.data.data.user,{ root: true }) 
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.token}`;
            
            resolve(response);
           })
           .catch(function (error) {
            reject(error);
          });
        })
    },

    register({},payload){
        return new Promise((resolve,reject) => {
           axios.post('api/auth/register',payload)
           .then(function (response){
              resolve(response)
           })
           .catch(function (error) {
            //handle error
              reject(error);
          });
        })
    },

    // firebase FCM
    storeFcmToken({},payload){
      return new Promise((resolve,reject)=>{
        axios.post('api/auth/update-fcm-token',payload)
        .then((response)=>{
          resolve(response)
        })
        .catch((error)=>{
          reject(error)
        })
      })
      
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        axios.post('api/auth/logout')
          .then(function () {
              resolve()
          })
          .catch(function () {
            reject
          })
      })
    },
}
