import axios from '@/axios.js'
const actions = {

    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////
    getDashboardStatistics({commit},){
      return new Promise((resolve,reject)=>{
        axios.get('api/dashboard/statistics')
        .then((response)=>{
          commit('UPDATE_DASHBOARD_STATISTICS',response.data.dashboard)
          resolve(response)
        }).catch((error)=>{
          reject(error)
        })
      })
      
    },
    getAllProducts({commit}){
      return new Promise((resolve,reject)=>{
          axios.get(`/products/data`)
          .then((response)=>{
              commit('SET_PRODUCTS',response.data)
              resolve(response)
          }).catch((error)=>{
              reject(error)
          })
      })
  },
  getAllFilters({commit}){
    return new Promise((resolve,reject)=>{
        axios.get(`/products/filters`)
        .then((response)=>{
            commit('SET_FILTERS',response.data)
            resolve(response)
        }).catch((error)=>{
            reject(error)
        })
    })
},
  
  getAllUsers({commit}){
    return new Promise((resolve,reject)=>{
        axios.get(`api/dashboard/users/users`)
        .then((response)=>{
            commit('SET_USERS',response.data)
            resolve(response)
        }).catch((error)=>{
            reject(error)
        })
    })
},
getAllCoupons({commit}){
  return new Promise((resolve,reject)=>{
      axios.get(`api/dashboard/coupons/coupons`)
      .then((response)=>{
          commit('SET_COUPONS',response.data)
          resolve(response)
      }).catch((error)=>{
          reject(error)
      })
  })
},

  getAllPackages({commit}){
    return new Promise((resolve,reject)=>{
        axios.get(`api/dashboard/packages/packages`)
        .then((response)=>{
            commit('SET_PACKAGES',response.data.packages)
            resolve(response)
        }).catch((error)=>{
            reject(error)
        })
    })
},
    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },
}

export default actions
