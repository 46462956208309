import axios from "@/axios.js"

export default {
    fetchCoupons({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`/coupons`)
            .then((response)=>{
                commit('SET_COUPONS',response.data.data)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

    createCoupon({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('/coupons',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    deleteCoupon({commit},id){
        return new Promise((resolve,reject)=>{
            axios.delete(`/coupons/${id}`)
            .then((response)=>{
                commit('DELETE_COUPON',id)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
}