import axios from "@/axios.js"
import { reject, resolve } from "core-js/fn/promise"

export default {
    //class a
    fetchOrders({commit},params){
        return new Promise((resolve,reject)=>{
            axios.get(`/orders?${params}`,)
            .then((response)=>{
                commit('SET_PAGINATION',response.data.data.last_page)
                commit('SET_ORDERS',response.data.data.data)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    archiveOrder({commit},id){
        return new Promise((resolve,reject)=>{
            axios.delete(`/orders/${id}`)
            .then((response)=>{
                commit('DELETE_ORDER',id)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchArchivedOrders({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`orders/archive`)
            .then((response)=>{
                commit('SET_PAGINATION',response.data.data.last_page)
                commit('SET_ARCHIVED',response.data.data.data)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    createNewOrder({commit},payload){
        commit('LoadingRequest', true);
        return new Promise((resolve,reject)=>{
            axios.post('/orders',payload)
            .then((response)=>{
                commit("LoadingRequest", false);
                resolve(response)
            }).catch((error)=>{
                commit("LoadingRequest", false);
                reject(error)
            })
        })
    },
    updateOrder({commit},payload){
        commit('LoadingRequest', true);
        return new Promise((resolve,reject)=>{
            payload.bodyFormData.set("_method", 'put' );
            axios.post(`/orders/${payload.id}`,payload.bodyFormData)
            .then((response)=>{
                commit("LoadingRequest", false);
                resolve(response)
            })
            .catch((error)=>{
                commit("LoadingRequest", false);
                reject(error)
            })
        })
    },

    //class b
    fetchClassbOrders({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`/orders/class-b/index?page=${payload.get('page')}`, payload)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchOrder({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`/orders/${id}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    updateClassBOrder({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`/orders/class-b/update/${payload.get('id')}`, payload)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    
    ////
    deleteClassbOrder({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/orders/delete/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    ////
    
    retrieveOrder({commit},id){
        return new Promise((resolve,reject)=>{
            axios.post(`/orders/restore/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    }
}