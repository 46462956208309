import axios from "@/axios.js"

export default {
  sendGlobalNotification({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/notifications`, payload)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
}
