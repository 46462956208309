export default {
    SET_OPTIONS(state,options){  state.options = options; },
    SET_CATEGORIES(state,data){  state.categories = data; },
    SET_SUB_CATEGORIES(state,data){  state.sub_categories = data; },
    SET_SKU(state,products){  state.products_sku = products; },
    SET_PRODUCT_DATA(state,product){  state.product = product},
    SET_PRODUCTS(state,products){  state.products = products},
    SET_PAGINATION(state,pagination){ state.pagination = pagination},
    SET_BRANDS(state,brands){  state.brands = brands},
    SET_GENDERS(state,genders){  state.genders = genders},
    SET_OFFERS(state,offers){  state.offers = offers},
    SET_COLORS(state,colors){  state.colors = colors},
    SET_PAGE(state,page){ state.currentPage = page }
}