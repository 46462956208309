export default {
    SET_OPTIONS(state,options){  state.options = options; },
    SET_PRODUCT_DATA(state,product){  state.product = product},
    SET_BRANDS(state,brands){  state.brands = brands},
    SET_GENDERS(state,genders){  state.genders = genders},
    SET_OFFERS(state,offers){  state.offers = offers},
    SET_COLORS(state,colors){  state.colors = colors},
    SET_TRANS(state,transactions){state.transactions = transactions},
    SET_STATS(state,stats){state.stats = stats},
    SET_ORDER_STATS(state, order_stats){state.order_stats = order_stats},
    SET_TRXN_STATS(state, trxn_stats){state.trxn_stats = trxn_stats},
    SET_CURRENCIES(state,currencies){  state.currencies = currencies; },


}
