import state from './moduleCategoryState'
import mutations from './moduleCategoryMutations'
import actions from './moduleCategoryActions'
import getters from './moduleCategoryGetters'

const namespaced = true

export default {
  namespaced,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}