import state from './moduleHomeStatsState.js'
import mutations from './moduleHomeStatsMutations.js'
import actions from './moduleHomeStatsActions.js'
import getters from './moduleHomeStatsGetters.js'

const namespaced = true

export default {
  namespaced,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
