import axios from '@/axios.js'

export default {
  fetchRepReport({commit}, payload){
    return new Promise((resolve, reject)=>{
      axios.post('/deliveries/report', payload)
      .then((response)=>{
          // commit('SET_REP_REPORT',response.data.data)
        resolve(response.data.data)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  fetchTransactionRequest({commit},payload){
    return new Promise((resolve, reject)=>{
      axios.post('transactions/report',payload)
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  fetchProductReport({commit},payload){
    return new Promise((resolve, reject)=>{
      axios.post('products/report',payload)
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  fetchUserReport({commit},payload){
    return new Promise((resolve, reject)=>{
      axios.post('/users/report',payload)
      .then((response)=>{
        resolve(response.data.data)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }
}
