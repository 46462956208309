import axios from '@/axios.js'
export default {

  // store-admins
  fetchStoreAdmins({commit}, page){
    return new Promise((resolve, reject)=>{
      axios.get(`/users/store-admin?page=${page}`)
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  fetchEndUsers({commit},params){
    return new Promise((resolve, reject)=>{
      axios.get(`/users?${params}`)
      .then((response)=>{
        commit('SET_USERS',response.data.data.data)
        commit('SET_PAGINATION',response.data.data.last_page)
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  fetchUnpaginated({commit}){
    return new Promise((resolve, reject)=>{
      axios.get(`/users/get-unpaginated`)
      .then((response)=>{
        commit('SET_UNPAGINATED_USERS',response.data.data)
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  fetchArchivedUsers({commit}){
    return new Promise((resolve, reject)=>{
      axios.get('/users/archive')
      .then((response)=>{
        commit('SET_ARCHIVED_USERS',response.data.data.data)
        commit('SET_PAGINATION',response.data.data.last_page)
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  fetchArchivedSellers({commit}){
    return new Promise((resolve, reject)=>{
      axios.get('users/archive-store-admin')
      .then((response)=>{
        commit('SET_ARCHIVED_SELLERS',response.data.data.data)
        commit('SET_PAGINATION',response.data.data.last_page)
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  deleteUser({commit},id){
    return new Promise((resolve,reject)=>{
      axios.delete(`/users/${id}`)
      .then((response)=>{
        commit('DELETE_USER',id)
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  

  retrieveUser({commit},itemId){
    return new Promise((resolve,reject)=>{
      axios.post(`/users/restore/${itemId}`)
      .then((response)=>{
        commit('DELETE_ARCHIVED_USER',itemId)
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  fetchStores({commit}){
    return new Promise((resolve, reject)=>{
      axios.get('/stores')
      .then((response)=>{
        commit('SET_STORES',response.data.data);
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  createStoreAdmin({commit},payload){
    return new Promise((resolve,reject)=>{
      axios.post(`/users`,payload)
      .then((response)=>{

        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  assignToStore({commit},payload){
    return new Promise((resolve,reject)=>{
      axios.post(`/users/${payload.get('user_id')}`,payload)
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

}