export default {
    options:[],
    product:{},
    products:[],
    pagination:'',
    products_sku:[],
    brands:[],
    genders:[],
    colors:[],
    offers:[],
    currentPage:1,
    categories:[],
    sub_categories:[],
}