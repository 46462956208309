import state from './moduleBrandVisitorsState'
import mutations from './moduleBrandVisitorsMutations'
import actions from './moduleBrandVisitorsActions'
import getters from './moduleBrandVisitorsGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
